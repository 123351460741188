import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/circleci/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const ExpandablePanel = makeShortcode("ExpandablePanel");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "description"
    }}>{`Description`}</h1>
    <p>{`A penalty fare is a fee given to someone caught travelling on a train without a valid ticket. The following qualifies as not having a valid ticket`}</p>
    <ul>
      <li parentName="ul">{`No ticket at all`}</li>
      <li parentName="ul">{`Wrong ticket (the ticket is valid, but not for the current travel)`}</li>
      <li parentName="ul">{`Wrong category (e.g. adult with a child ticket)`}</li>
      <li parentName="ul">{`Expired ticket`}</li>
    </ul>
    <h2 {...{
      "id": "workflow"
    }}>{`Workflow`}</h2>
    <p>{`The following is the workflow for creating a penalty fare "order". The first main section is how to create the penalty fare, and the two subsections describe the two ways to charge for the penalty fare.`}</p>
    <h3 {...{
      "id": "create-a-penalty-fare-order"
    }}>{`Create a penalty fare "order"`}</h3>
    <ol>
      <li parentName="ol">{`Create a temporary customer with a five years expiration date using the Customers API: `}<a parentName="li" {...{
          "href": "https://petstore.swagger.io/?url=https://api.entur.io/api-docs/customers-api#/Temporary%20profiles%20client/createTemporaryProfile"
        }}>{`POST /profiles/temporary`}</a>{`.`}</li>
    </ol>
    <p>{`The following fields must be set in the request:`}</p>
    <ul>
      <li parentName="ul">{`dateOfBirth`}</li>
      <li parentName="ul">{`firstName`}</li>
      <li parentName="ul">{`surname`}</li>
      <li parentName="ul">{`countryCode`}</li>
      <li parentName="ul">{`telephoneNumber`}</li>
      <li parentName="ul">{`email`}</li>
      <li parentName="ul">{`organisationId`}</li>
      <li parentName="ul">{`createdBy - to identify who created the customer`}</li>
      <li parentName="ul">{`createdByChannel`}</li>
      <li parentName="ul">{`expirationDate (five years from the time the penalty fare was issued)`}</li>
    </ul>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Update the temporary customer with postal address using the Customers API: `}<a parentName="li" {...{
          "href": "https://petstore.swagger.io/?url=https://api.entur.io/api-docs/customers-api#/Profiles%20client/createPostalAddressByCustomerNumber"
        }}>{`POST /profiles/{customerNumber}/postal-addresses`}</a>{`.`}</li>
    </ol>
    <p>{`Alternative to step 1. and 2. is to create a normal customer profile with expirationDate set.`}</p>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`Fetch penalty policies applicable for the train/route/operator where the penalised got caught without a valid ticket: `}<a parentName="li" {...{
          "href": "https://petstore.swagger.io/?url=https://api.entur.io/products/api-docs/swagger.json#/penalty-policies/findByOrganisationIdOrFareFrameId_10"
        }}>{`GET /penalty-policies`}</a>{`.`}</li>
    </ol>
    <p>{`Use `}<inlineCode parentName="p">{`authority`}</inlineCode>{` as request parameter.`}</p>
    <ol {...{
      "start": 4
    }}>
      <li parentName="ol">{`Create an order: `}<a parentName="li" {...{
          "href": "https://petstore.swagger.io/?url=https://api.entur.io/api-docs/order?group%3dpublic#/Order%20API/createOrder"
        }}>{`POST /v1/orders`}</a>{`.`}</li>
    </ol>
    <p>{`For penalty fares, it is important that the order is connected to the temporary profile created in step 1. This is done by having the following body in the request:`}</p>
    <ExpandablePanel title="Example request" mdxType="ExpandablePanel">
    <pre>
        {`{
    "contactInfo": {
        "createdBy": {
            "id": 12345
        },
        "contact": {
            "id": 12345
        }
    }
}
`}
      </pre>
    </ExpandablePanel>
    <p>{`where contact and createdBy is the customerId of the temporary profile.`}</p>
    <ol {...{
      "start": 5
    }}>
      <li parentName="ol">{`Add a fee to the newly created order: `}<a parentName="li" {...{
          "href": "https://petstore.swagger.io/?url=https://api.entur.io/api-docs/order?group%3dpublic#/Order%20API/addFeeToOrder"
        }}>{`POST /v1/orders/{orderId}/fees`}</a>{`.`}</li>
    </ol>
    <p>{`The fee should be created based on the penalty policy fetched in step 3. The body should look somewhat like this:`}</p>
    <ExpandablePanel title="Example request" mdxType="ExpandablePanel">
    <pre>
        {`{
    "orderLineIds": [],
    "price": {
        "amount": "1150.00",
        "currency": "NOK",
        "taxRate": "0.00",
        "taxAmount": "0.00",
        "taxCode": "06"
    },
    "externalRef": {"id": "NSB:PenaltyPolicy:InspectionFeeInvoice", "version": "NSB:Version:V1" },
    "reason": "Gebyr",
    "type": "PENALTY"
}`}
      </pre>
    </ExpandablePanel>
    <pre><code parentName="pre" {...{}}>{`        The fields in the body should be set the following way:

        - price should be the price of the penalty policy
        - externalRef should be the id and version of the penalty policy
        - reason should be a text that described the reason for the fee. E.g. \`Penalty fare: no valid ticket\`.
        - type should always be \`PENALTY\`
`}</code></pre>
    <ol {...{
      "start": 6
    }}>
      <li parentName="ol">
        <p parentName="li">{`Add PenaltyFareMetadata to the order: TODO()`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Perform payment as documented below`}</p>
      </li>
    </ol>
    <h4 {...{
      "id": "on-site-payment"
    }}>{`On-site payment`}</h4>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Create a payment: `}<a parentName="p" {...{
            "href": "https://petstore.swagger.io/?url=https://api.entur.io/v3/api-docs/payment?group%3dpublic#/Payment%20API/createPaymentUsingPOST"
          }}>{`POST /v1/payments`}</a>{`.`}</p>
        <p parentName="li">{`Nothing special to note here, basically do the same as in `}<a parentName="p" {...{
            "href": "https://developer.entur.org/pages-sales-guides#create-payment"
          }}>{`this guide`}</a>{`.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Import the transaction: `}<a parentName="p" {...{
            "href": "https://petstore.swagger.io/?url=https://api.entur.io/v3/api-docs/payment?group%3dpublic#/Payment%20API/importTransactionUsingPOST"
          }}>{`POST /v1/payments/{paymentId}/transactions/{transactionId}/import`}</a>{`.`}</p>
      </li>
    </ol>
    <h4 {...{
      "id": "invoice-payment"
    }}>{`Invoice payment`}</h4>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Create a payment: `}<a parentName="p" {...{
            "href": "https://petstore.swagger.io/?url=https://api.entur.io/v3/api-docs/payment?group%3dpublic#/Payment%20API/createPaymentUsingPOST"
          }}>{`POST /v1/payments`}</a>{`.`}</p>
        <p parentName="li">{`Nothing special to note here, basically do the same as in `}<a parentName="p" {...{
            "href": "https://developer.entur.org/pages-sales-guides#create-payment"
          }}>{`this guide`}</a>{`.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Add a payment transaction to the payment: `}<a parentName="p" {...{
            "href": "https://petstore.swagger.io/?url=https://api.entur.io/v3/api-docs/payment?group%3dpublic#/Payment%20API/addTransactionUsingPOST"
          }}>{`POST /v1/payments/{paymentId}/transactions`}</a>{`.`}</p>
        <p parentName="li">{`The following fields should be set:`}</p>
        <ul parentName="li">
          <li parentName="ul">{`paymentType should be `}<inlineCode parentName="li">{`XLEDGER`}</inlineCode></li>
          <li parentName="ul">{`paymentTypeGroup should be `}<inlineCode parentName="li">{`INVOICE`}</inlineCode></li>
          <li parentName="ul">{`recurringPaymentId should `}<em parentName="li">{`not`}</em>{` be set`}</li>
          <li parentName="ul">{`amount should be the price of the penalty fare`}</li>
          <li parentName="ul">{`currency should be `}<inlineCode parentName="li">{`NOK`}</inlineCode></li>
        </ul>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Create an invoice: `}<a parentName="p" {...{
            "href": "https://petstore.swagger.io/?url=https://api.entur.io/v3/api-docs/payment?group%3dpublic#/Payment%20API/invoiceUsingPOST"
          }}>{`POST /v1/payments/v1/payments/{paymentId}/transactions/{transactionId}/invoice`}</a>{`.`}</p>
        <p parentName="li">{`The following fields should be set:`}</p>
        <ul parentName="li">
          <li parentName="ul">{`customerIdentificationNumber - should be a unique identifier for the penalised traveller`}</li>
          <li parentName="ul">{`externalInvoiceNumber - should be a unique identifier for the invoice given to the penalised traveller.`}</li>
        </ul>
        <p parentName="li">{`The two other fields should not be set.`}</p>
        <p parentName="li">{`When this operation is done, the transaction is set to CAPTURED, which will produce a PaymentTransactionCapturedEvent. This in turn will make the order CONFIRMED.
Unlike most other payments, there is no need to perform a capturePayment-operation to capture the transaction. This is because we're not actually receiving a payment that we're able to capture.
Instead, the responsibility to collect the payment is moved to an external system (XLEDGER).`}</p>
      </li>
    </ol>
    <h3 {...{
      "id": "creditcancel-a-penalty-fare"
    }}>{`Credit/cancel a penalty fare`}</h3>
    <p>{`The process to credit a penalty fare is the same for both types of payment:`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Delete the fee from the order: `}<a parentName="p" {...{
            "href": "https://petstore.swagger.io/?url=https://api.entur.io/api-docs/order?group%3dpublic#/Order%20API/deleteFeeFromOrder"
          }}>{`DELETE /v1/orders/{orderId}/fees/{feeId}`}</a>{`.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Create a credit: `}<a parentName="p" {...{
            "href": "https://petstore.swagger.io/?url=https://api.entur.io/v3/api-docs/payment?group%3dpublic#/Payment%20API/createCreditUsingPOST"
          }}>{`POST /v1/credits`}</a>{`.`}</p>
      </li>
    </ol>
    <ExpandablePanel title="Example request" mdxType="ExpandablePanel">
    <pre>
        {`{
             "creditTransactions": [
                 {
                     "amount": 1150,
                     "currency": "NOK",
                     "paymentTransactionId": 0
                 }
             ],
             "currency": "NOK",
             "customerNumber": 123456789,
             "orderId": "UEBWKJNX",
             "orderVersion": 2,
             "totalCreditAmount": 1150
        }`}
    </pre>
    </ExpandablePanel>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`Execute the credit: `}<a parentName="li" {...{
          "href": "https://petstore.swagger.io/?url=https://api.entur.io/v3/api-docs/payment?group%3dpublic#/Payment%20API/executeCreditUsingPOST"
        }}>{`POST /v1/credits/{creditId}/execute`}</a>{`.`}</li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      